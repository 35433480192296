/* Kontener dla wyboru budowy i frazy wyszukiwania */
.select-container {
    margin-bottom: 20px;
    text-align: center;
}

.select-dropdown, .date-picker, .search-input {
    width: 100%;
    max-width: 250px;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 10px auto;
    display: block;
}

.select-dropdown:focus, .date-picker:focus, .search-input:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

/* Stylowanie przycisku wyszukiwania */
.search-button {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
    transition: background-color 0.3s;
}

.search-button:hover {
    background-color: #0056b3;
}

/* Stylowanie wyników i galerii zdjęć */
.date-entry {
    margin-bottom: 20px;
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px;
    text-align: center;
}

.date-entry h3 {
    cursor: pointer;
    color: #007bff;
}

.date-entry p {
    font-size: 16px;
    margin-top: 10px;
}

.button-container {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 10px;
}

.button-container button {
    padding: 5px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.button-container button:first-child {
    background-color: #28a745;
    color: #fff;
}

.button-container button:last-child {
    background-color: #6c757d;
    color: #fff;
}

.button-container button:hover {
    opacity: 0.8;
}

/* Stylowanie miniatur zdjęć w galerii */
.photo-gallery {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 10px;
}

.photo-thumbnail {
    width: 150px;
    height: 150px;
    object-fit: cover;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    margin: 5px;
}

/* Modalne okno dla powiększonych zdjęć */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    cursor: pointer;
    color: #333;
}

.modal-arrow {
    background: none;
    border: none;
    font-size: 36px;
    color: #333;
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1001;
}

.modal-arrow.left {
    left: 10px;
}

.modal-arrow.right {
    right: 10px;
}

.modal-content img {
    max-width: 100%;
    max-height: 90vh;
    border-radius: 5px;
}

.zip-progress {
    display: inline-flex;
    align-items: center;
    margin-right: 10px;
    position: relative;
}

.spinner {
    width: 40px; /* Rozmiar kółka */
    height: 40px;
    border: 4px solid #f3f3f3;
    border-top: 4px solid #3498db;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

.spinner-circle {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 4px solid #ccc;
    border-top-color: #1d72b8;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

.spinner-percentage {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 12px; /* Rozmiar tekstu procentowego */
    color: #333;
    /* Brak animacji dla procentów, więc pozostają statyczne */
}

@keyframes spin {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
}
