.upload-form {
    max-width: 400px;
    width: calc(100% - 40px); /* Pełna szerokość pomniejszona o margines */
    margin: 20px auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #f9f9f9;
    box-sizing: border-box; /* Uwzględnia padding w szerokości */
}

.upload-form * {
    max-width: 100%; /* Zapobiega przekroczeniu szerokości */
    box-sizing: border-box; /* Uwzględnia padding i marginesy */
}

.upload-form button,
.upload-form select,
.upload-form textarea,
.upload-form input[type="file"],
.upload-form input[type="date"],
.upload-form .react-datepicker__input-container input {
    width: 100%; /* Ustawienie na 100% szerokości kontenera */
    padding: 12px;
    margin-top: 5px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
    background-color: #fff;
    color: #333;
}

@media (max-width: 600px) {
    .upload-form {
        margin: 20px; /* Zwiększony margines po bokach na małych ekranach */
    }
    .upload-form select.custom-select {
        padding: 16px 12px; /* Większy padding dla lepszej wysokości */
        font-size: 16px; /* Większy font dla czytelności */
    }
}

.upload-form button {
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.upload-form button:hover {
    background-color: #0056b3;
}
